export const New = () => {
  return (
    <div className="new">
      <p className="new__title">What's news?</p>
      <p className="new__description">
        Zaktualizowana sekcja Bonusy i promocje
      </p>
      <p className="new__description">
        Dodano lokalizację najbliższego Oddziału, Bankomatu lub Terminala na
        mapie.
      </p>
      <p className="new__description">Dodano obsługę Face ID i Touch ID.</p>
      <p className="new__description">
        Naprawiono problem z powiadomieniami push, którego doświadczali
        niektórzy użytkownicy Androida 10.
      </p>
      <p className="new__description">
        Rozwiązaliśmy problem powodujący, że tekst w ustawieniach był bardzo
        mały dla użytkowników Androida.
      </p>
    </div>
  );
};
